<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<div>
  <a class="pageTitle" title="Request Review List"  >{{headerListName}}</a>
  <br><br>
  <div class="row">
    <div class="col-xs-6">
      <!--<div style="margin-left:-1%;">
        <label for="Request Creation Year">Year Of Request Creation </label>&nbsp;
        <select style="height: 25px;"  name="yearOfRequest" aria-placeholder="Select Year" [(ngModel)]="selectedYear" (change)="onYearChange()" id="yearOfRequest" class="form-control">
          <option value="" selected disabled>Select Year</option>
          <option *ngFor="let option of yearsList" [value]="option">
            {{option}}
          </option>
        </select>
      </div>-->
    </div>
    <div class="col-xs-6">
      <div class="search-bar">
        <button *ngIf="isDashboardPage || isExistingRequestPage"
                [disabled]="isEmailinProgress"
                class="primary icon-button blue-button"
                type="button"
                (click)="isDashboardPage ? initiateEmail() : initiateEmailForExistingRequests(); $event.preventDefault()"
                [ngStyle]="isExistingRequestPage ? {'margin-right': '51%'} : {}">
          Generate Email
        </button>
        <span  *ngIf="isDashboardPage" [ngStyle]="getMailMsgStyles()" class="maillabelStyles">{{mailStatusMessage}}</span>
        <span  [ngStyle]="criteria.toLowerCase() == 'to be billed' ? {'margin-right': '51%'} : {}">
          <button *ngIf="criteria.toLowerCase() == 'to be billed'"
           class="primary blue-button" type="button" (click)="exportToExcel()">
            Generate Report
          </button>
        </span>
        <span>
          <button *ngIf="criteria.toLowerCase() == 'existing requests' || criteria.toLowerCase() == 'to be billed'"
                  [disabled]="isBulkUpdateenable" class="primary blue-button" type="button" (click)="multiUpdateItem($event)">
            <!-- <mat-icon>update</mat-icon></button> -->
            Bulk Update</button>
        </span>
    <input type="text" [(ngModel)]="searchText" #filter>
        <button class="primary" type="button" (click)="applyGlobalFilter(filter.value)"><mat-icon>search</mat-icon></button>
        <button class="primary" type="button" (click)="resetFilters()"><mat-icon>clear all</mat-icon></button>
      </div>
      </div>
</div>
  <!-- <mat-form-field>
    <input matInput (keyup)="Filterchange($event)" placeholder="enter text" />
  </mat-form-field> -->
  <div>  
    <span>
      <span style="font-weight:bold; color: #777777; ">Count:</span>{{TotalCountforReportforallotment}}
    </span>
  </div>
  <div *ngIf="criteria.toLowerCase() == 'to be billed'">
    <span>
      <span style="font-weight: bold; color: #777777;">Total Cost:</span>
      {{TotalCost|currency:'USD'}}
    </span>
  </div>
<br/>
  <!-- <table matSort mat-table [dataSource]="dataSource">
    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
      <th mat-header-cell *matHeaderCellDef> {{column}} </th>
      <td mat-cell *matCellDef="let element"> {{element[column]}} </td>

    </ng-container>
    <ng-container *ngFor="let column of displayedColumns">
      <ng-container *ngIf="column === 'Title';" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>Title</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>filter_list</mat-icon>
            </button>
          </div>


        </th>
        <td mat-cell *matCellDef="let element" style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; align-items: center;">
          <div style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; align-items: center;display: flex;">
            <span matTooltip="{{element.Title}}" style="flex-grow: 1; display: block;" class="wrap-text">
              {{element.Title}}
            </span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon style="font-size: 16px;">more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="criteria.toLowerCase() != 'existing requests' && criteria.toLowerCase() != 'to be billed'" mat-menu-item (click)="editItem(element)">
                <span class="edit-menu">Edit Item</span>
              </button>
              <button *ngIf="criteria.toLowerCase() != 'existing requests' && criteria.toLowerCase() != 'to be billed'" mat-menu-item (click)="deleteItem(element)">
                <span class="edit-menu">Delete Item</span>
              </button>
              <button mat-menu-item (click)="viewItem(element)">
                <span class="edit-menu">View Item</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>
      <ng-container *ngIf="column !== 'Title';" [matColumnDef]="column">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>
          {{column}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element[column]}} </td>

      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table> -->
<!-- New Grid -->
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container *ngFor="let col of columns">

        <ng-container *ngIf="col.columnDef === 'Title';" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>Title</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>
            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText}}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>

          </th>
          <td mat-cell *matCellDef="let element" style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; align-items: center;">
            <div style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; align-items: center;display: flex;">
              <span matTooltip="{{element.Title}}" style="flex-grow: 1; display: block;" class="wrap-text">
                {{element.Title|replaceNullWithText}}
              </span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon style="font-size: 16px;">more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="editItem(element)">
                  <span class="edit-menu">Edit Item</span>
                </button>
                <button mat-menu-item (click)="deleteItem(element)">
                  <span class="edit-menu">Delete Item</span>
                </button>
                <button mat-menu-item (click)="viewItem(element)">
                  <span class="edit-menu">View Item</span>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'Cost'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText|currency:'USD' }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText |currency:'USD'}} </td>
        </ng-container>
        <!-- CheckBox for Existing View -->
        <ng-container *ngIf="col.columnDef == 'Select';" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox color="primary" [checked]="ispageWiseSelectAllChecked" (click)="$event.stopPropagation()" (change)="onSelectAllChecked($event)"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> <mat-checkbox color="primary" [checked]="element.IsSelected" (click)="$event.stopPropagation()" (change)="onCheckboxChecked(element,$event)"></mat-checkbox> </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'Created'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText | date:'MMMM dd, yyyy' }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText | date:'MMMM dd, yyyy'}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'ReportPeriodStartDate'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText | date:'MM/dd/yyyy' }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'RequestDate'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText | date:'MM/dd/yyyy' }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'ReportPeriodEndDate'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText | date:'MM/dd/yyyy' }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'FRRD'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText | date:'MM/dd/yyyy' }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'FRCD'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText | date:'MM/dd/yyyy' }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'DeliveryDate'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText | date:'MM/dd/yyyy' }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'Modified'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option |replaceNullWithText | date:'MMMM dd, yyyy'}}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText | date:'MMMM dd, yyyy'}} </td>
        </ng-container>

        <ng-container *ngIf="col.columnDef == 'ModifiedByName'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ "Modified By"}}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option |replaceNullWithText }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText }} </td>
        </ng-container>

        <ng-container *ngIf="col.columnDef == 'WBSClientName'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ "WBS#:ClientName" }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'RequestorsName'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ "Requestor's Name" }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option |replaceNullWithText}}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'WBS'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ "WBS#" }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'ExclusiveToClient'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ "Exclusive To Client?" }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'NeedByDate'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText | date:'MM/dd/yyyy' }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef == 'AllottedDate'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText | date:'MM/dd/yyyy' }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef !== 'Title'&& col.columnDef !== 'FRRD' &&col.columnDef !== 'FRCD' && col.columnDef !== 'Select' && col.columnDef !== 'Cost' &&  col.columnDef !== 'Created' && col.columnDef !=='ReportPeriodStartDate' && col.columnDef !=='ReportPeriodEndDate' && col.columnDef !=='RequestDate' && col.columnDef !=='DeliveryDate' && col.columnDef !=='Modified' && col.columnDef !=='WBSClientName' && col.columnDef !=='RequestorsName' && col.columnDef !=='WBS' && col.columnDef !=='ExclusiveToClient' && col.columnDef !='NeedByDate' && col.columnDef !='AllottedDate' && col.columnDef != 'ModifiedByName'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyColumnFilter($event, option, col.columnDef)">
                    {{ option|replaceNullWithText }}
                  </mat-checkbox>
                </button>
              </div>
              <!-- <div style="display: flex; justify-content: space-between;">
            <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                Apply
            </button>
            <button mat-menu-item (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-button">
                Clear
            </button>
        </div> -->
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
        </ng-container>
      </ng-container>  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<br>
<span class="noValues" *ngIf="!isGridDataAvailable">There are no items to show in this view</span>
  <mat-paginator [pageSizeOptions]="[50,100,200]" showFirstLastButtons></mat-paginator>
  <div *ngIf="isLoading" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0); display: flex; justify-content: center; align-items: center;">
    <!-- <mat-spinner [diameter]="40"></mat-spinner> -->
  </div>
</div>
