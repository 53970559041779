<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<div>
<table style="width:100%">
  <!--<tr>
    <td style="color:red;"><h2  class="blink">**Alert!!</h2></td>
  </tr>
  <tr>
    <td>
      <span class="header-app-label">
         Welcome to the new SAR portal! This is an upgraded version of our old website. We have recently moved to this new portal on 11/04. 
         In case you face any issues with our new website please write to us at <a href="mailto:US HYD Service Auditor Report Review" style="color:#0072c6;">US HYD Service Auditor Report Review</a> 
      </span>      
    </td>
  </tr>-->
  <tr>
    <td style="text-align: center;">
      <a class="pageTitle" title="SAR Master List">Search SAR Master List</a>
    </td>
  </tr>
</table>
<form #searchForm="ngForm" style="padding-top:10px">
    <table cellspacing="2" cellpadding="2" style="width:80%">
        <tbody>
            <tr>
                <td valign="top" colspan="2" style="width:70%;"><span><span style="font-family: Verdana; color: #000066; "><font size="5">Inquiry/Request Initiation Screen Test<br><br></font></span></span></td>
                <td>
                    <span>
                        <div style="border:#24A44F 4px solid;text-align:left;font-size:10pt;margin-right: -21px;margin-top: -30px;position: absolute;width: 37%;">
                            <b>Note:</b><br>To lodge your request for Service Auditor Report Review, perform a search, then in the search results
                            click on the service organization name at the row/record that you need and click on “click here” link. If you do not
                            find what you are looking for in the search results, use the “Request a New Review” option on the left side panel.
                            USD 500 per report will be charged for each report/request.<br><br>In addition to performing the Service Auditor Report (SAR)
                            reviews, USI can also help out with other related services, such as: Mapping of complementary user entity controls to user
                            organization controls; Mapping of service organization controls to audit ROMMs; Evaluation of exceptions listed in the service
                            auditors report; revising the process flow-charts to include the process steps and controls performed by the service organization.
                            You can write to <a style="color: #337AB7" href="mailto:US HYD Service Auditor Report Review"><u>US HYD Service Auditor Report Review</u></a> mail box
                            separately with specific requirements.<br><br>
                        </div>
                    </span>
                </td>
            </tr>
            <tr>
                <td valign="top" colspan="2" style="width:70%;"><span><span style="font-family: 'Segoe UI', 'Segoe', Tahoma, Helvetica, Arial, sans-serif; font-size: 9pt">Type a keyword or abbreviation in one or more of the search criteria fields, such as Service Organization or Application. For example:<ul><li>If you look for Automatic Data Processing, Inc., type ADP, and you get the list of SAR Review Memos starting with ADP.</li><li>You can also limit the search results for a particular period end by entering the date in the “Period End Date” field.</li><li> You can also search by entering the full abbreviation (for example, The Variable Annuity Life Insurance Company is also commonly <br> known as “VALIC,”.</li></ul> </span><br></span></td>
            </tr>
            <tr>
                <td valign="top" style="padding-top:7px;"><span>Service Organization:</span></td>
                <td valign="top"><input type="text" [(ngModel)]="searchFormRequest.serviceOrganization" name="serviceOrganization"></td>
            </tr>
            <tr>
                <td valign="top" style="padding-top:7px;"><span>Application:</span></td>
                <td valign="top"><input type="text" [(ngModel)]="searchFormRequest.application" name="application"></td>
            </tr>
            <tr>
                <td valign="top" style="padding-top:7px;"><span>Service Auditor:</span></td>
                <td valign="top"><input type="text" [(ngModel)]="searchFormRequest.serviceAuditor" name="serviceAuditor"></td>
            </tr>
            <tr>
                <td valign="top" style="padding-top:7px;"><span>Period Start Date:</span></td>
                <td valign="top"><input type="date" [(ngModel)]="searchFormRequest.periodStartDate" name="periodStartDate" class="wide-input" #StartDate></td>
            </tr>
            <tr>
                <td valign="top" style="padding-top:7px;"><span>Period End Date:</span></td>
                <td valign="top"><input type="date" [(ngModel)]="searchFormRequest.periodEndDate" name="periodEndDate" class="wide-input" #EndDate></td>
            </tr>
            <br>
            <tr>
                <td>
                    <div style="display: flex; justify-content: left; align-items: flex-start;">
                        <div mat-dialog-actions>
                            <button mat-button (click)="onSubmit(searchForm,StartDate,EndDate)" class="ms-button buttonstyle" style="color: #FFFFFF;" >Submit</button>
                            <button mat-button (click)="onClearClick(searchForm)" class="ms-button buttonstyle" style="color: #FFFFFF;" >Clear</button>
                        </div>
                    </div>
                </td>
                <td></td>
            </tr>
            <br>
        </tbody>
    </table>
</form>


<!-- <div id="view-master-dashboard" *ngIf="showDashboard">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="ServiceOrganization">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Service Organization</th>
            <td mat-cell *matCellDef="let element" class="wrap-text" (click)="viewItem(element)" style="color: blue; cursor: pointer;"><u>{{element.ServiceOrganization}}</u></td>
        </ng-container>

        <ng-container matColumnDef="Application">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Application</th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.Application}} </td>
        </ng-container>

        <ng-container matColumnDef="ServiceAuditor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Service Auditor</th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.ServiceAuditor}} </td>
        </ng-container>

        <ng-container matColumnDef="ReportPeriodStartDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Report Period Start Date</th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.ReportPeriodStartDate | date:'MM/dd/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="ReportPeriodEndDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Report Period End Date</th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.ReportPeriodEndDate | date:'MM/dd/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="LocationsCovered">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Locations Covered in Report</th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.LocationsCovered}} </td>
        </ng-container>

        <ng-container matColumnDef="TypeOfOpinion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>TypeOfOpinion</th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.TypeOfOpinion}} </td>
        </ng-container>

        <ng-container matColumnDef="SARReportType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>SAR Report Type</th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.SARReportType}} </td>
        </ng-container>

        <ng-container matColumnDef="SARAuthoritativeGuidance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>SAR Authoritative Guidance</th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.SARAuthoritativeGuidance}} </td>
        </ng-container>

        <ng-container matColumnDef="IDNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID Number</th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.IDNumber}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [length]="dataSource.data.length" [pageSize]="10" showFirstLastButtons></mat-paginator>

</div>
<div *ngIf="isLoading" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0); display: flex; justify-content: center; align-items: center;">
    <mat-spinner [diameter]="40"></mat-spinner> 
</div> -->

<div class="mat-elevation-z8" *ngIf="showDashboard">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="ServiceOrganization">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Service Organization</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('ServiceOrganization')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options" >
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('ServiceOrganization'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ServiceOrganization')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ServiceOrganization')">
                        {{ option }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('ServiceOrganization'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element" class="wrap-text" (click)="viewItem(element)" style="color: #337AB7; cursor: pointer;"><u>{{element.ServiceOrganization}}</u></td>
        </ng-container>

        <ng-container matColumnDef="Application">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Application</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('Application')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('Application'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('Application')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'Application')">
                        {{ option }}
                      </mat-checkbox>
                    </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('Application'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.Application}} </td>
        </ng-container>

        

        <ng-container matColumnDef="ServiceAuditor">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Service Auditor</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('ServiceAuditor')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('ServiceAuditor'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ServiceAuditor')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ServiceAuditor')">
                        {{ option }}
                      </mat-checkbox>
                    </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('ServiceAuditor'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.ServiceAuditor}} </td>
        </ng-container>

        <ng-container matColumnDef="ReportPeriodStartDate">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Report Period Start Date</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('ReportPeriodStartDate')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('ReportPeriodStartDate'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ReportPeriodStartDate')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ReportPeriodStartDate')">
                        {{ option }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('CreatedBy'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.ReportPeriodStartDate| date:'MMMM dd, yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="ReportPeriodEndDate">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Report Period End Date</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('ReportPeriodEndDate')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('ReportPeriodEndDate'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ReportPeriodEndDate')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ReportPeriodEndDate')">
                        {{ option }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('ID'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.ReportPeriodEndDate | date:'MMMM dd, yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="LocationsCovered">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Location(s) Covered in Report</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('LocationsCovered')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('LocationsCovered'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('LocationsCovered')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'LocationsCovered')">
                        {{ option }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('LocationsCovered'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.LocationsCovered}} </td>
        </ng-container>

        <ng-container matColumnDef="TypeOfOpinion">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Type Of Opinion</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('TypeOfOpinion')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('TypeOfOpinion'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('TypeOfOpinion')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'TypeOfOpinion')">
                        {{ option }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('Modified'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.TypeOfOpinion }} </td>
        </ng-container>

        <ng-container matColumnDef="SARReportType">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>SAR Report Type</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('SARReportType')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('SARReportType'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('SARReportType')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'SARReportType')">
                        {{ option }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('ModifiedBy'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.SARReportType}} </td>
        </ng-container>

        <ng-container matColumnDef="SARAuthoritativeGuidance">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>SAR Authoritative Guidance</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('SARAuthoritativeGuidance')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('SARAuthoritativeGuidance'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('SARAuthoritativeGuidance')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'SARAuthoritativeGuidance')">
                        {{ option }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('SARAuthoritativeGuidance'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.SARAuthoritativeGuidance}} </td>
        </ng-container>

        <ng-container matColumnDef="IDNumber">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>ID Number</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('IDNumber')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('IDNumber'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('IDNumber')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'IDNumber')">
                        {{ option }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('TypeOfOpinion'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.IDNumber}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
   
</div>
<mat-paginator *ngIf="showDashboard" [length]="dataSource.data.length" [pageSize]="50" showFirstLastButtons></mat-paginator>
<span class="noValues" *ngIf="!isGridDataAvailable">There are no items to show in this view</span>
<span class="noValues" *ngIf="isInvalidDate"> Date is Invalid</span>
    <div *ngIf="isLoading" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0); display: flex; justify-content: center; align-items: center;">
        <!-- <mat-spinner [diameter]="40"></mat-spinner> -->
    </div>

</div>
